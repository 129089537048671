import './TableList.scss';
import { interfaceTableList } from '../../Interface/PropsInterface';
import AddIcon from '@mui/icons-material/Add';
import ResourceNotFound from '../../Assets/Icons/ResourceNotFound.png';
import Loader from '../../Reusable/Loader';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '../../Reusable/Button/Button';

const TableList = ({
  disableOption = true,
  isModify,
  list,
  loader,
  errorMessage,
  pathModify,
  routeParams,
  labelsTable,
  disabledUser,
  onUpdatedItem,
  onRelease,
  deleteBtn,
  onDelete,
}: interfaceTableList) => {
  const navigate = useNavigate();

  // generate field table
  const generateLabelTable = (item: any) => {
    let rows: any = [];

    // generate rows
    labelsTable.forEach((label, index) => {

      const text = label === 'delay' ? (item[label] !== '' ? `+ ${item[label]}` : '') : item[label];
      const delayBadge = (label === 'delay' && item[label] !== '');
      const automaticBadge = (label === 'verifyType' && item[label] == 'Demone - Automatica');
      const assenzaBadge = (label === 'badgeType' && String(item[label]).includes('Assenza'));
      
      if (label === 'note') {
        rows.push(
          <td key={index} className='TableList__column'>
            <textarea
              value={item['note']}
              placeholder='Inserire nota'
              onChange={(e) => {
                item['note'] = e.target.value;
                onUpdatedItem?.(item);
              }}
              onBlur={onRelease}
            />
          </td>,
        );
        return;
      }

      if (item[label] && label !== 'idUtente') {
        
        if (label === 'workedTime') {
          rows.push(
            <td key={index} className='TableList__column'>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else if (label === 'totalWorkedTime') {
          rows.push(
            <td key={index} className='TableList__column'>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else if (label === 'totalNonWorkedTime') {
          rows.push(
            <td key={index} className='TableList__column'>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else {
          rows.push(
            <td key={index} className= {`TableList__column ${delayBadge || assenzaBadge ? 'isDelay' : ''} ${automaticBadge ? 'isAutomatic' : ''}`}>
              <p className={`TableList__text `}>
                {typeof item[label] === 'boolean' ? String(item[label]) : String(text)}
              </p>
            </td>,
          );
        }

      } else if (label !== 'idUtente') {

        if (label === 'workedTime') {
          rows.push(
            <td key={index} className='TableList__column'>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms')  || String(item[label]).includes('0')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else if (label === 'totalWorkedTime') {
          rows.push(
            <td key={index} className='TableList__column'>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms') || String(item[label]).includes('0')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else if (label === 'totalNonWorkedTime') {
          rows.push(
            <td key={index} className= {`TableList__column ${delayBadge || assenzaBadge ? 'isDelay' : ''}`}>
              <p className={`TableList__text `}>
                { (String(item[label]).includes('0ms') || String(item[label]).includes('0')) ? '-' : item[label] }
              </p>
            </td>,
          );
        }else{
          rows.push(
            <td key={index} className={`TableList__column`}>
              <p className={`TableList__text`}>{ String(item[label]).includes('undefined') ? '-' : String(text) }</p>
            </td>,
          );
        }


      }


    });

    return rows;
  };

  // modify
  const modifyHandler = (id: any) => {
    if (routeParams) {
      navigate(`${pathModify}/${id}`);
      return;
    }
    navigate(pathModify as string);
  };

  // delete user
  const disableUser = (item: any) => {
    disabledUser?.(item);
  };

  // delete badge
  const deleteBadge = (uuid: string) => {
    onDelete?.(uuid);
  };

  return (
    <tbody className='TableList'>
      {loader && (
        <tr>
          <td colSpan={8} className='p-8'>
            <div className='flex flex-col items-center'>
              <Loader />
            </div>
          </td>
        </tr>
      )}
      {!loader && list.length === 0 && (
        <tr>
          <td colSpan={8} className='p-8'>
            <div className='flex flex-col items-center'>
              <img className='TableList__image' src={ResourceNotFound} alt='resource not found' />
              <p className='TableList__text'>{errorMessage}</p>
            </div>
          </td>
        </tr>
      )}
      {!loader &&
        list.length > 0 &&
        list?.map((item: any, index: number) => {
          return (
            <tr className='TableList__content' key={index}>
              {generateLabelTable(item)?.map((row: any) => {
                return row;
              })}
              {isModify && (
                <td className='TableList__column TableList__column--info'>
                  <div
                    className='TableList__box mr-2 flex items-center'
                    onClick={() => modifyHandler(item?.idUtente || item?.uuid)}
                  >
                    <AddIcon />
                    <p>Info</p>
                  </div>
                  {disableOption && item.disabled && (
                    <div
                      className='TableList__box--active flex items-center'
                      onClick={() => disableUser(item)}
                    >
                      <AccountCircleIcon />
                      <p>Attiva utente</p>
                    </div>
                  )}
                  {disableOption && !item.disabled ? (
                    <div
                      className='TableList__box--delete flex items-center'
                      onClick={() => disableUser(item)}
                    >
                      <AccountCircleIcon />
                      <p>Disabilita</p>
                    </div>
                  ) : null}
                </td>
              )}
              {deleteBtn && (
                <td className='TableList__column TableList__column--info'>
                  <Button
                    title='Elimina'
                    additionalClass='Table-deleteBtn'
                    onClick={() => deleteBadge(item.uuid)}
                  />
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableList;
