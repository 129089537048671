import { useState } from 'react';
import './CreateBadge.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../Reusable/Dropdown/Dropdown';
import { Transition } from 'react-transition-group';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../../Reusable/Button/Button';
import useHttp from '../../../Hooks/useHttp';
import { AsyncCreateBadge, AsyncLogout } from '../../../Redux/Actions/Actions';
import Loader from '../../../Reusable/Loader';
import Field from '../../../Reusable/Field/Field';
import { Buffer } from 'buffer';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const CreateBadge = ({ onNotification, onCloseModal }) => {
  const [dropdownUser, setDropdownUser] = useState(false);
  const [dropdownTimbre, setDropdownTimbre] = useState(false);
  const [dropdownLocation, setDropdownLocation] = useState(false);
  const [badge, setBadge] = useState({
    uuid: uuidv4(),
    lat: 0,
    lng: 0,
    device: 'Web',
    verifyType: 'Web',
    badgeType: 'Entrata',
    workType: 'Presenza',
  });
  const { httpRequest, loader } = useHttp();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.root.users);
  const navigate = useNavigate();


  // status dropdown user
  const statusDropdownUserHandler = () => {
    if (dropdownTimbre && dropdownLocation) {
      setDropdownTimbre(false);
      setDropdownLocation(false);
    } else if (dropdownTimbre) {
      setDropdownTimbre(false);
    } else {
      setDropdownLocation(false);
    }
    setDropdownUser((prev) => !prev);
  };

  // create list user
  const createListUser = () => {
    const copyArray = [...users].sort((a, b) => a.surname.localeCompare(b.surname));
    const Users = copyArray.map((user) => {
      return user.name + " " + user.surname + " " + user.email;
    });

    return Users;
  };

  // user choiced
  const userChoiced = (user) => {
    const copyArray = [...users];
    const User = copyArray.find((item) => {
      const userEmail = item.email;
      return (user.split(" ")[2]).trim() === userEmail.trim();
    });

    setBadge({
      ...badge,
      idUtente: User.idUtente,
      nickname: User.nickname,
      email: User.email
    });
    setDropdownUser(false);
  };


  // status dropdown timbre
  const statusDropdownTimbreHandler = () => {
    setDropdownTimbre((prev) => !prev);
  };

  // timbre choiced
  const timbreChoiced = (timbre) => {
    switch (timbre) {
      case 'Entrata': {
        setBadge({
          ...badge,
          badgeType: 'Entrata',
        });
        break;
      }

      case 'Inizio Permesso': {
        setBadge({
          ...badge,
          badgeType: 'Inizio-Permesso',
        });
        break;
      }

      case 'Fine Permesso': {
        setBadge({
          ...badge,
          badgeType: 'Fine-Permesso',
        });
        break;
      }

      case 'Inizio Pranzo': {
        setBadge({
          ...badge,
          badgeType: 'Pausa-Pranzo',
        });
        break;
      }

      case 'Ritorno Pranzo': {
        setBadge({
          ...badge,
          badgeType: 'Ritorno-Pranzo',
        });
        break;
      }

      case 'Inizio Trasferta': {
        setBadge({
          ...badge,
          badgeType: 'Inizio-Trasferta',
        });
        break;
      }

      case 'Fine Trasferta': {
        setBadge({
          ...badge,
          badgeType: 'Fine-Trasferta',
        });
        break;
      }

      case 'Fine Trasferta Uscita': {
        setBadge({
          ...badge,
          badgeType: 'Fine-Trasferta-Uscita',
        });
        break;
      }

      case 'Uscita': {
        setBadge({
          ...badge,
          badgeType: 'Uscita',
        });
        break;
      }

      case 'Assenza': {
        setBadge({
          ...badge,
          badgeType: 'Assenza',
        });
        break;
      }
    }
    setDropdownTimbre(false);
  };

  // status dropdown location
  const statusDropdownLocationHandler = () => {
    setDropdownLocation((prev) => !prev);
  };

  // location choiced
  const locationChoiced = (location) => {
    setBadge({
      ...badge,
      workType: location,
    });
    setDropdownLocation(false);
  };

  // time
  const changeTimeHandler = (e) => {
    console.log(e.target.value);
    setBadge({
      ...badge,
      timestamp: e.target.value,
    });
  };

    // note
    const changeNoteHandler = (e) => {
      setBadge({
        ...badge,
        note: e.target.value,
      });
    };

  // date
  const changeDateHandler = (e) => {
    console.log(e.target.value);
    setBadge({
      ...badge,
      date: e.target.value,
    });
  };

  // create badge
  const createBadgeHandler = async (e) => {
    e.preventDefault();
    const { timestamp, date } = badge;

    const key = Buffer.from(
      `${moment(date + timestamp, 'YYYY-MM-DDLT').valueOf()},${badge.idUtente},${badge.workType},${
        badge.badgeType
      },ppaegdabmtm`,
    ).toString('base64');

    // create new badge
    const newBadge = {
      ...badge,
      encoded: key,
    };

    const response = await httpRequest(process.env.REACT_APP_CREATEBADGE__API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify(newBadge),
    });

    // if response go to error
    if (typeof response === 'function') {
      if (response().status === 401) {
        onNotification({
          type: 'Error',
          message: response().message,
        });
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        dispatch(AsyncLogout());
        alert("Sessione Scaduta");
        navigate('/');
      }

      onNotification({
        type: 'Error',
        message: response().message,
      });
      return;
    }

    // save
    dispatch(AsyncCreateBadge(badge));
    onNotification({
      type: 'Success',
      message: 'Badge creato con successo',
    });
    onCloseModal();
  };

  return (
    <form className='CreateBadge relative' onSubmit={createBadgeHandler}>
      <div className='mb-6'>
        <label className='CreateBadge__label'>Utente</label>
        <div
          className='CreateBadge__select flex justify-between relative'
          onClick={statusDropdownUserHandler}
        >
          <span className='CreateBadge__select__option'>
            {badge?.idUtente ? badge?.email : 'Scegli utente'}
          </span>
          <span>
            {dropdownUser ? (
              <ExpandLessIcon className='CreateBadge__select__icon' />
            ) : (
              <ExpandMoreIcon className='CreateBadge__select__icon' />
            )}
          </span>
          <Transition in={dropdownUser} timeout={500} appear unmountOnExit>
            {(state) => (
              <Dropdown
                dropdownType='select'
                header={false}
                list={createListUser()}
                onItemChoice={(user) => userChoiced(user)}
                animation={`dropdown--select--${state}`}
                onClose={statusDropdownUserHandler}
              />
            )}
          </Transition>
        </div>
      </div>
      <div className='row items-center'>
        <div className='mb-6 col-xs-12 col-sm-6'>
          <label className='CreateBadge__label'>Timbratura</label>
          <div
            className='CreateBadge__select flex justify-between relative'
            onClick={statusDropdownTimbreHandler}
          >
            <span className='CreateBadge__select__option'>
              {badge?.badgeType ? badge.badgeType : 'Entrata'}
            </span>
            <span>
              {dropdownTimbre ? (
                <ExpandLessIcon className='CreateBadge__select__icon' />
              ) : (
                <ExpandMoreIcon className='CreateBadge__select__icon' />
              )}
            </span>
            <Transition in={dropdownTimbre} timeout={500} appear unmountOnExit>
              {(state) => (
                <Dropdown
                  dropdownType='select'
                  header={false}
                  list={[
                    'Entrata',
                    'Inizio Permesso',
                    'Fine Permesso',
                    'Inizio Pranzo',
                    'Ritorno Pranzo',
                    'Inizio Trasferta',
                    'Fine Trasferta',
                    'Fine Trasferta Uscita',
                    'Uscita',
                    'Assenza',
                  ]}
                  onItemChoice={(timbre) => timbreChoiced(timbre)}
                  animation={`dropdown--select--${state}`}
                  onClose={statusDropdownTimbreHandler}
                />
              )}
            </Transition>
          </div>
        </div>
        <div className='mb-6 col-xs-12 col-sm-6'>
          <label className='CreateBadge__label'>Luogo</label>
          <div
            className='CreateBadge__select flex justify-between relative'
            onClick={statusDropdownLocationHandler}
          >
            <span className='CreateBadge__select__option'>
              {badge?.workType ? badge.workType : 'Presenza'}
            </span>
            <span>
              {dropdownLocation ? (
                <ExpandLessIcon className='CreateBadge__select__icon' />
              ) : (
                <ExpandMoreIcon className='CreateBadge__select__icon' />
              )}
            </span>
            <Transition in={dropdownLocation} timeout={500} appear unmountOnExit>
              {(state) => (
                <Dropdown
                  dropdownType='select'
                  header={false}
                  list={['Presenza', 'Smart-Working']}
                  onItemChoice={(location) => locationChoiced(location)}
                  animation={`dropdown--select--${state}`}
                  onClose={statusDropdownLocationHandler}
                />
              )}
            </Transition>
          </div>
        </div>
        <div className='CreateBadge__hour col-xs-12'>
          <Field
            type='time'
            name='Orario badge'
            onChange={changeTimeHandler}
            value={badge.timestamp}
          />
        </div>
        <div className='CreateBadge__hour col-xs-12'>
          <Field type='date' name='Data' onChange={changeDateHandler} value={badge.date} />
        </div>
        <div className='CreateBadge__hour col-xs-12'>
        <Field
            type='text'
            name='Note'
            placeholder='Note aggiuntive'
            onChange={changeNoteHandler}
            value={badge.note}
          />
        </div>
      </div>
      <Button
        disabled={!badge?.idUtente || !badge?.badgeType || !badge?.workType || !badge?.timestamp || !badge?.date}
        title={loader ? <Loader /> : 'Conferma'}
        additionalClass='w-full'
      />
    </form>
  );
};

export default CreateBadge;
